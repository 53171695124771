import './App.css';
import React from 'react';
import { PopupButton } from '@typeform/embed-react'

import './bootstrap.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function App() {
  const [realName, setRealName] = React.useState("");

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <div className="App">
      <p>Typeform Demo</p>
      Name:
      <input
        type="text"
        id="realName"
        value={realName}
        onChange={(e) => setRealName(e.target.value)}          
      />
      <PopupButton
        id="xfdYJ4Pt"
        size={80}
        hidden={{
          name: realName,
          id: 'user1234567890/module1/part1',
        }}
        onReady={() => {
          console.log('form ready')
        }}

        onSubmit={(e) => {
          console.log(e)
          setShow(true)
        }}
        enableSandbox
      >
        Launch Module 1
      </PopupButton>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Module 1, part 1 complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You've completed module 1, part 1, {realName}.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default App;
